<template>
  <div id='myProject' class='myProject'>
    <v-alert dense class='alert' :value='tipsInfo' :type='tipsInfoType'>{{ tipsInfoContent }}</v-alert>
	
    <div class='indexTop'>
      <div class='top_left'>
        <div class='left_img'>
          <img src='../../assets/logo1.png' alt='logo1' />
          <div class='left_title'>中华人民共和国潍坊海事局</div>
        </div>
      </div>
      <div class='top_center'>
        <div class='center_text'>AI溢油监测系统</div>
      </div>
	  <div class='top_right3' v-show='showUserList' @click='showStaffList'>员工列表</div>
	  <div class='top_right4' v-show='showUserList' @click='uoUserPass(userDetails)'>{{userDetails.userName}}</div>
	  <div class='top_right3' v-show='!showUserList' @click='uoUserPass(userDetails)'>{{userDetails.userName}}</div>
      <div class='top_right2' @click='loginOut'>退出登录</div>
      <div class='top_right'>
        <div class='newTiem'>{{ nowTime }}</div>
      </div>
    </div>
	

    <div class='index_content'>
      <div class='content_left'>
        <div class='panel bar'>
          <h2>潍坊海事局监控总数</h2>
          <div class='panel_content'>
            <div id='monitorTotal' style='width: 100%; height: 300px' />
          </div>
          <div class='panel-footer'></div>
        </div>
        <div class='panel bar' style='margin-top: 20px'>
		  <img class='setting' src="../../assets/seting.png" v-show='showCameraList' @click='getcameraList'/>
          <h2>扫测记录</h2>
		  
          <div class='panel_content'>
            <div class='cycle_list'>
              <div class='rollScreen_container' id='rollScreen_container'>
                <ul
                  class='rollScreen_list'
                  :style='{ transform: transform }'
                  :class='{ rollScreen_list_unanim: num === 0 }'
                >
                  <li class='rollScreen_once' v-for='(item, index) in videoList' :key='index'
                      :style="'color:'+ cameraListType[item.cameraStatus]">
                    <div>{{ item.lastCheckTime }}</div>
                    <div class='rollScreen_address'>{{ item.cameraLocationName }}</div>
                    <div>{{ cameraList[item.cameraStatus] }}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class='panel-footer'></div>
        </div>
      </div>
	  
      <div class='content_center'>
        <div class='monitorNumber'>
          <div class='monitorNumber-hd'>
            <div>{{ cameraNumber.cameraCount }}</div>
            <div class='line_vertical'></div>
            <div>{{ cameraNumber.cameraDetectCount }}</div>
          </div>
          <div class='monitorNumber-bd'>
            <div>监测摄像头数量(个)</div>
            <div style='min-width: 1px'></div>
            <div>检测扫描次数(次)</div>
          </div>
        </div>
        <div class='address_info'>
		  <img class='setting' src="../../assets/seting.png" v-show='isSettingShow && showCameraList' @click="settingShow()" />
		  <img class='send' src="../../assets/send.png" v-show='!isSettingShow && showCameraList'  @click="sendSettingHide()"/>
		  <img class='add' src="../../assets/add.png" v-show='!isSettingShow && showCameraList'   @click="addSettingHide()"/>
		  
			  
		  <vue-draggable-resizable
			v-model="areaList"
			v-show='!isSettingShow && showCameraList'
			v-for='(item, index) in areaList' :key='index'
			:w="item.coordinateStyle.w" 
			:h="item.coordinateStyle.h" 
			:x="item.coordinateStyle.x" 
			:y="item.coordinateStyle.y"
		    :parent="true"
		    @dragging="(x,y)=>onDrag(x,y,index)"
			style="position: absolute;"
		  >
			  <div class='setting_border' >
				<img class='delectImg' src='../../assets/delect.png' alt='删除地域' @click="delectArea(index)" />
				<div class='progressBar title'>
					<input  v-model="item.areaName" style="color: #f5f5f5;"/>
				</div>
				<div class='pB_bottom'>
				  <div style='margin-right:5px;'>
					监控数量：{{ item.cameraCount }}
				  </div>
				  <div>
					疑似问题：<span style='color: red'>{{item.errorCount }}</span>
				  </div>
				</div>
				<div class='progressBorder'>
				  <v-progress-linear
					class='progressBar'
					striped
					rounded
					height='8px'
					v-model='index%2==0? percentage1 : percentage2'
					color='cyan lighten-2'
				  ></v-progress-linear>
				  <span class='percentage'>{{ index%2==0? percentage1 : percentage2}}%</span>
				</div>
				 <div class='line' :style='"transform-origin:"+item.lineStyle.to+";display: "+item.lineStyle.display+";top:"+item.lineStyle.y+"px;left:"+item.lineStyle.x+"px;height:"+item.lineStyle.h+"px;transform: rotate("+item.lineStyle.f+"deg);"'></div>
			  </div>
		  </vue-draggable-resizable>
		  <vue-draggable-resizable
				v-model="areaList"
				v-show='!isSettingShow && showCameraList'
				v-for='(item, index) in areaList' :key='"img_"+index'
				:w="item.imgStyle.w" 
				:h="item.imgStyle.h" 
				:x="item.imgStyle.x" 
				:y="item.imgStyle.y"
				:parent="true"
				@dragging="(x,y)=>onDragImg(x,y,index)"
				style="position: absolute;"
		  >
		  	<img class='coordinateImg' src='../../assets/coordinate1.png' :alt='item.areaName' />
		  </vue-draggable-resizable>
		  
		<div class='coordinate' v-model="areaList" v-for='(item, index) in areaList' :key='"show_"+index' @click="addressCameraClick(item.areaId)" v-show='isSettingShow'
			:style="'top:'+item.coordinateStyle.y+'px;left:'+item.coordinateStyle.x+'px;'" >
		  <div class='progressBar title'>
			{{ item.areaName }}
		  </div>
		  <div class='pB_bottom'>
			<div style='margin-right:5px;'>
			  监控数量：{{ item.cameraCount}}
			</div>
			<div>
			  疑似问题：<span style='color: red'>{{ item.errorCount}}</span>
			</div>
		  </div>
		  <div class='progressBorder'>
			<v-progress-linear
			  class='progressBar'
			  striped
			  rounded
			  height='8px'
			  v-model='index%2==0? percentage1 : percentage2'
			  color='cyan lighten-2'
			></v-progress-linear>
			<span class='percentage'>{{ index%2==0? percentage1 : percentage2 }}%</span>
		  </div>
		  <div class='line' :style='"transform-origin:"+item.lineStyle.to+";display: "+item.lineStyle.display+";top:"+item.lineStyle.y+"px;left:"+item.lineStyle.x+"px;height:"+item.lineStyle.h+"px;transform: rotate("+item.lineStyle.f+"deg);"'></div>
		</div>
		<img v-for='(item, index) in areaList' :key='"show_img_"+index' @click="addressCameraClick(item.areaId)" v-show='isSettingShow'  class='coordinateImg'
		 src='../../assets/coordinate1.png' :alt='item.areaName' :style="'top:'+item.imgStyle.y+'px;left:'+item.imgStyle.x+'px;'"/>
		
        </div>
      </div>
      <div class='content_right'>
        <div class='panel bar'>
          <h2>港口溢油疑似风险</h2>
          <div class='panel_content'>
            <div class='table_list'>
              <div class='table_title'>
                <div style='width: calc(68% - 80px)'>所属区域</div>
                <div style='width: calc(64% - 80px)'>监控点名称</div>
                <div style='width: 80px'>状态</div>
                <div style='width: 80px'>操作</div>
              </div>
              <div class='table_content' v-for='(item, index) in leakOilIssues' :key='index'>
                <div style='width: calc(68% - 80px)'>{{ item.camera.area.areaName }}</div>
                <div style='width: calc(64% - 80px)'>{{ item.camera.cameraLocationValue }}</div>
                <div style='width: 80px;color: #fff789'>
                  {{ typeList[item.imgStatus] }}
                </div>
                <div style='width: 80px; cursor: pointer;color: #00e4ff' @click='videoClick(item)'>详情</div>
              </div>
              <v-pagination class='pagination' @input='pageChange' v-model='page' dark :length='length'></v-pagination>
            </div>
          </div>
          <div class='panel-footer'></div>
        </div>
        <div class='panel bar' style='margin-top: 20px; min-height: 605px; min-width: 400px'>
          <h2>数据源</h2>
          <div class='panel_content'>
            <div class='monitor'>
              <div class='monitor_content clearfix'>
                <div class='monitor_div'>
                  <img :src='videoList.length > 0 && videoList[0].lastImgPath'
                       v-bind:alt='videoList.length > 0 && videoList[0].cameraLocationName' @click='videoPictures(videoList[0])'/>
					    <!-- <img :src="require('../../../' +videoList[0].lastImgPath)" /> -->
                  <div class='text'>
                    {{ videoList.length > 0 && videoList[0].cameraLocationValue }}
                  </div>
                </div>
                <div class='monitor_div2' v-for='(item, index) in videoList' :key='index' v-show='index > 0'>
                  <img :src='item.lastImgPath' v-bind:alt='item.cameraLocationName' @click='videoPictures(item)'/>
                  <div class='text'>{{ item.cameraLocationValue }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class='panel-footer'></div>
        </div>
        
      </div>
    </div>
    
	
	<!-- 摄像头状态 -->
    <v-dialog v-model='cameraItemDialog' max-width='1145'>
      <v-card class='dialogCard' color='#323941'>
        <div class='close' @click='cameraItemDialog = false'>
          <v-icon size='33' color='#00E4FF'>mdi-close-circle</v-icon>
        </div>
        <v-card-text>
          <v-container>
            <v-row>
              <img style='max-height: 550px' :src='cameraItem.lastImgPath' v-bind:alt='cameraItem.cameraLocationName' />
            </v-row>
          </v-container>
        </v-card-text>
        <div class='dialogCard_text'>
          <span> {{ cameraItem.cameraLocationName }} ({{ cameraList[cameraItem.cameraStatus] }})</span>
        </div>
      </v-card>
    </v-dialog>
    <!-- 弹框状态修改 -->
    <v-dialog v-model='ProjectDialog' max-width='1145'>
      <v-card class='dialogCard' color='#323941'>
        <div class='close' @click='ProjectDialog = false'>
          <v-icon size='33' color='#00E4FF'>mdi-close-circle</v-icon>
        </div>
        <v-card-text>
          <v-container>
            <v-row>
              <img style='max-height: 550px' :src='imageContent.imgPath' alt='' />
            </v-row>
          </v-container>
        </v-card-text>
        <div class='dialogCard_text'>
          {{ imageContent.cameraLocationValue
          }}<span v-if='imageContent.imgStatus'>({{ imageList[imageContent.imgStatus] }})</span>
        </div>
        <v-card-actions class='dialogBottom'>
          <!-- <div style="background:#D94040" v-if="imagecontent.imgStatus == 1" @click="ProjectDialog = false"> 误报 </div> -->
          <div style='background: #e59e2c' @click='updateImageClick(2, imageContent.imgId)'>
            忽略
          </div>
          <div style='background: #00b2c7' @click='updateImageClick(0, imageContent.imgId)'>
            处理
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 删除 -->
	<v-dialog v-model='areaDialogDelete' max-width='340px'>
      <v-card>
        <v-card-title class='text-h5'>是否要删除当前地域？</v-card-title>
        <v-card-text>*注意：删除后数据无法恢复！</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color='grey darken-2' text @click='areaDialogDelete = false'> 取消</v-btn>
          <v-btn color='light-blue accent-3' text @click='delectAreaCamer'> 确认</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
	<!-- 修改密码 -->
	<v-dialog v-model='upUserLog' max-width='420px'>
		<v-card>
		  <v-card-text>
			<v-container>
			  <v-form ref='formUser' v-model='valid' lazy-validation>
				<v-row>
				  <v-card-title>
				    <span class='text-h5'>修改密码</span>
				  </v-card-title>
				  <v-col cols='12' style='padding: 0 12px'>
					<v-text-field
					  label='密码 *'
					  outlined
					  :rules='rules'
					  v-model='addUserList.passWord'
					  required
					></v-text-field>
				  </v-col>
				</v-row>
			  </v-form>
			</v-container>
		  </v-card-text>
		  <v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color='blue darken-1' style='color: #fff' @click='SureUserChangePass'> 确定</v-btn>
		  </v-card-actions>
		</v-card>
	</v-dialog>
    <!-- 员工管理 -->
    <v-dialog v-model='staffListDialog' max-width='1200px'>
      <v-card class='dialogCard' color='#323941'>
        <v-toolbar-title class='dialogtitle'> 员工列表</v-toolbar-title>
        <div class='close' @click='staffListDialog = false'>
          <v-icon size='33' color='#00E4FF'>mdi-close-circle</v-icon>
        </div>
        <v-card-text>
          <v-container>
            <v-row>
              <v-dialog v-model='addUserdialog' max-width='420px'>
                <template v-slot:activator='{ on, attrs }'>
                  <v-btn color='primary' dark class='mb-2' v-bind='attrs' v-on='on' @click='addUserClick'>
                    <v-icon color='#fff'>mdi-plus</v-icon>
                    新增
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class='text-h5'>{{ ifUser }}员工</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-form ref='formUser' v-model='valid' lazy-validation>
                        <v-row>
                          <v-col cols='12' style='padding: 0 12px' v-show="ifUser === '新增'">
                            <v-text-field
                              label='账号 *'
                              outlined
                              :rules='rules'
                              v-model='addUserList.userName'
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols='12' style='padding: 0 12px'>
                            <v-text-field
                              label='密码 *'
                              outlined
                              :rules='rules'
                              v-model='addUserList.passWord'
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols='12' style='padding: 12px 12px 0' v-show="ifUser !== '新增'">
                            <v-select
                              label='权限 *'
                              outlined
                              :items='userRoleList'
                              :item-text='(item) => `${userRoueList[item.roleName]}`'
                              :item-value='(item) => `${item.roleId}`'
                              :rules='rules'
                              v-model='addUserList.roleName'
                              required
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color='blue darken-1' style='color: #fff' @click='SureUserClick'> 确定</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
			  
              <v-dialog v-model='userDialogDelete' max-width='340px'>
                <v-card>
                  <v-card-title class='text-h5'>是否要删除当前员工？</v-card-title>
                  <v-card-text>*注意：删除后此员工将不能再访问此程序！</v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color='grey darken-2' text @click='userDialogDelete = false'> 取消</v-btn>
                    <v-btn color='light-blue accent-3' text @click='deleteItemUser'> 确认</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
			  
              <div class='table_list'>
                <div class='table_title'>
                  <div>序号</div>
                  <div>权限</div>
                  <div>账号</div>
                  <div>操作</div>
                </div>
                <div class='table_content' v-for='(item, index) in userLists' :key='index'>
                  <div>{{ index }}</div>
                  <div>{{ item.roleList.length > 0 ? userRoueList[item.roleList[0].roleName] : '' }}</div>
                  <div>{{ item.userName }}</div>
                  <div style='cursor: pointer'>
                    <span @click='editUserClick(item)' style='color: #26a8ba; margin-right: 10px'>修改</span>
                    <span @click='deleteUserClick(item)' style='color: red'>删除</span>
                  </div>
                </div>
                <v-pagination
                  class='pagination2'
                  @input='userPageChange'
                  v-model='userListpage'
                  dark
                  :length='userListlength'
                ></v-pagination>
              </div>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- 摄像头管理 -->
    <v-dialog v-model='cameraListDialog' max-width='90%'>
      <v-card class='dialogCard' color='#323941'>
        <v-toolbar-title class='dialogtitle'> 摄像头管理</v-toolbar-title>
        <div class='close' @click='cameraListDialog = false'>
          <v-icon size='33' color='#00E4FF'>mdi-close-circle</v-icon>
        </div>
        <v-card-text>
          <v-container>
            <v-row>
              <v-dialog v-model='adddialog' max-width='700px'>
                <template v-slot:activator='{ on, attrs }'>
                  <v-btn color='primary' dark class='mb-2' v-bind='attrs' v-on='on' @click='wenzi()'>
                    <v-icon color='#fff'>mdi-plus</v-icon>
                    新增
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class='text-h5'>{{ camername }}摄像头</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-form ref='form' v-model='valid' lazy-validation>
                        <v-row>
                          <v-col cols='12' style='padding: 12px 12px 0'>
                            <v-select
                              label='监控地点名称 *'
                              outlined
                              :items='chartList'
                              :item-text='(item) => `${item.areaName}`'
                              :item-value='(item) => `${item.areaId}`'
                              :rules='rules'
                              v-model='addCamerList.areaId'
                              required
                            ></v-select>
                          </v-col>
                          <v-col cols='12' style='padding: 0 12px'>
                            <v-text-field
                              label='监控详细地点 *'
                              outlined
                              :rules='rules'
                              v-model='addCamerList.cameraLocationValue'
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols='12' style='padding: 0 12px'>
                            <v-select
                              label='摄像头型号 *'
                              outlined
                              :items='cameraModelList'
                              :item-text='(item) => `${item.cameraName}`'
                              :item-value='(item) => `${item.cameraType}`'
                              :rules='rules'
                              v-model='addCamerList.cameraType'
                              required
                            ></v-select>
                          </v-col>
                          <v-col cols='8' style='padding: 0 12px'>
                            <v-text-field
                              label='摄像头IP地址 *'
                              outlined
                              :rules='rules'
                              v-model='addCamerList.cameraIp'
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols='4' style='padding: 0 12px'>
                            <v-text-field
                              label='摄像头IP地址端口号 *'
                              outlined
                              :rules='rules'
                              v-model='addCamerList.port'
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols='12' style='padding: 0 12px'>
                            <v-text-field
                              label='摄像头账号 *'
                              outlined
                              :rules='rules'
                              v-model='addCamerList.cameraUsername'
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols='12' style='padding: 0 12px'>
                            <v-text-field
                              label='摄像头密码 *'
                              outlined
                              :rules='rules'
                              v-model='addCamerList.cameraPassword'
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols='12' style='padding: 0 12px'>
                            <v-text-field
                              label='经度 *'
                              outlined
                              :rules='rules'
                              v-model='addCamerList.longitude'
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols='12' style='padding: 0 12px'>
                            <v-text-field
                              label='纬度 *'
                              outlined
                              :rules='rules'
                              v-model='addCamerList.latitude'
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color='blue darken-1' style='color: #fff' @click='cameraListClick'>确认</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model='camerDialogDelete' max-width='340px'>
                <v-card>
                  <v-card-title class='text-h5'>是否要删除当前摄像头？</v-card-title>
                  <v-card-text>*注意：删除后此摄像头包括所有存储图片都会丢失！</v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color='grey darken-2' text @click='camerDialogDelete = false'> 取消</v-btn>
                    <v-btn color='light-blue accent-3' text @click='deleteItemCamer'> 确认</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <div class='table_list'>
                <div class='table_title'>
                  <div style='width: 160px'>序号</div>
                  <div>监控地点</div>
                  <div>详细地点</div>
                  <div style='width: 340px'>型号</div>
                  <div style='width: 380px'>IP地址</div>
                  <div style='width: 380px'>IP端口号</div>
                  <div style='width: 340px'>账号</div>
                  <div style='width: 340px'>密码</div>
                  <div style='width: 220px'>状态</div>
                  <div style='width: 260px'>经度</div>
                  <div style='width: 160px'>纬度</div>
                  <div style='width: 280px'>操作</div>
                </div>
                <div class='table_content' v-for='(item, index) in cameraLists' :key='index'>
                  <div style='width: 160px'>{{ index }}</div>
                  <div>{{ item.area.areaName }}</div>
                  <div>{{ item.cameraLocationValue }}</div>
                  <div style='width: 340px'>{{ modelDictionary[item.cameraType] }}</div>
                  <div style='width: 380px'>{{ item.cameraIp }}</div>
                  <div style='width: 380px'>{{ item.port }}</div>
                  <div style='width: 340px'>{{ item.cameraUsername }}</div>
                  <div style='width: 340px'>{{ item.cameraPassword }}</div>
                  <div style='width: 220px' :style="'color:' + cameraListType[item.cameraStatus]">
                    {{ modelType[item.cameraStatus] }}
                  </div>
                  <div style='width: 260px'>{{ item.longitude }}</div>
                  <div style='width: 160px'>{{ item.latitude }}</div>
                  <div style='width: 280px; cursor: pointer'>
                    <span @click='editcamerClick(item)' style='color: #26a8ba; margin-right: 10px'>修改</span>
                    <span @click='deletecamerClick(item)' style='color: red'>删除</span>
                  </div>
                </div>
                <v-pagination
                  class='pagination2'
                  @input='cameraPageChange'
                  v-model='cameraListpage'
                  dark
                  :length='cameraListlength'
                ></v-pagination>
              </div>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- 地区列表 -->
    <v-dialog v-model='addressCameraListDialog1' max-width='90%'>
      <v-card class='dialogCard' color='#323941'>
        <!-- <v-toolbar-title class="dialogtitle"> 摄像头管理 </v-toolbar-title> -->
        <div class='close' @click='addressCameraListDialog1 = false'>
          <v-icon size='33' color='#00E4FF'>mdi-close-circle</v-icon>
        </div>
        <v-card-text>
          <v-container>
            <v-row>
              <div class='table_list'>
                <div class='table_title'>
                  <div style='width: 180px'>序号</div>
                  <div>地区</div>
                  <div style='width: 280px'>操作</div>
                </div>
                <div class='table_content' v-for='(item, index) in cameraCountGroupList' :key='index'>
                  <div style='width: 180px'>{{ index }}</div>
                  <div>{{ item.cameraName }}</div>
                  <div style='width: 280px; cursor: pointer'>
                    <span @click='videoPictures(item)' style='color: #26a8ba'>图像预览</span>
                  </div>
                </div>
              </div>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
	<!-- 地区摄像头列表 -->
    <v-dialog v-model='addressCameraListDialog' max-width='90%'>
      <v-card class='dialogCard' color='#323941'>
        <!-- <v-toolbar-title class="dialogtitle"> 摄像头管理 </v-toolbar-title> -->
        <div class='close' @click='addressCameraListDialog = false'>
          <v-icon size='33' color='#00E4FF'>mdi-close-circle</v-icon>
        </div>
        <v-card-text>
          <v-container>
            <v-row>
              <div class='table_list'>
                <div class='table_title'>
                  <div style='width: 180px'>序号</div>
                  <div>监控地点</div>
                  <div>详细地点</div>
                  <div style='width: 380px'>IP地址</div>
                  <div style='width: 220px'>状态</div>
                  <div style='width: 280px'>操作</div>
                </div>
                <div class='table_content' v-for='(item, index) in addressLists' :key='index'>
                  <div style='width: 180px'>{{ index }}</div>
                  <div>{{ item.area.areaName }}</div>
                  <div>{{ item.cameraLocationValue }}</div>
                  <div style='width: 380px'>{{ item.cameraIp }}</div>
                  <div style='width: 220px' :style="'color:' + cameraListType[item.cameraStatus]">
                    {{ modelType[item.cameraStatus] }}
                  </div>
                  <div style='width: 280px; cursor: pointer'>
                    <span @click='videoPictures(item)' style='color: #26a8ba'>图像预览</span>
                  </div>
                </div>
                <v-pagination
                  class='pagination2'
                  @input='addressPageChange'
                  v-model='addressListpage'
                  dark
                  :length='addressListlength'
                ></v-pagination>
              </div>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  addCamera,
  addUser,
  cameraCountNumber,
  cameraIssueList,
  cameraList,
  cameraListByAreaId,
  deleteCamera,
  deleteUser,
  editCamera,
  editUser,
  getAreaCameraCount,
  getQueryCameraCountGroup,
  getRoleMy,
  getUser,
  getUserRole,
  updateImage,
  getAreaRole,
  editArea,
  delectArea
} from '../../api/account'

import {VueGridLayout	} from 'vue-grid-layout'


export default {
  data: () => ({
	isSettingShow:true,
	areaList:[],
	areaId:'',
	areaDialogDelete:false,
	areaIndex:0,
    valid: true,
    tipsInfo: false,
    tipsInfoType: 'success',
    tipsInfoContent: '',
    nowTime: '',
    showUserList: false,
    showCameraList: false,
    //监控地点
    chartList: [],
    imageContent: {},
    cameraItem: {},
    page: 1,
    length: 1,
    leakOilIssues: [],
    ProjectDialog: false,
    cameraItemDialog: false,
    staffListDialog: false,
    cameraListDialog: false,
    //港口溢油疑似风险字典
    typeList: {
      0: '处理完成',
      1: '待处理',
      2: '忽略'
    },
    //处理状态字典
    cameraList: {
      0: '正常',
      1: '溢油',
      2: '断连'
    },
    //处理状态字典
    imageList: {
      0: '正常',
      1: '待处理',
      2: '处理中'
    },
    //型号字典
    modelDictionary: {
      0: '海康威视_新版',
      1: '海康威视_老版',
      2: '大华',
      3: '宇视'
    },
    cameraModelList: [
      { cameraType: '0', cameraName: '海康威视_新版' },
      { cameraType: '1', cameraName: '海康威视_老版' },
      { cameraType: '2', cameraName: '大华' },
      { cameraType: '3', cameraName: '宇视' }
    ],
    //摄像头状态字典
    modelType: {
      0: '正常',
      1: '溢油',
      2: '断连'
    },
    cameraListType: {
      0: '#34d379',
      1: '#E7032C',
      2: '#ACB1B7'
    },
    //员工权限字段
    userRoueList: {
      DEFAULT: '员工',
      ADMIN: '超级管理员'
    },
    videoList: [],
    cameraCountGroupList: [],
    num: 0,
    //进度条
    percentage1: 45,
	percentage2: 0,
    //员工列表
    userRoleList: [],
    addUserdialog: false,
	upUserLog:false,
    userDialogDelete: false,
    userLists: [],
    userListlength: 1,
    userListpage: 1,
    userId: '',
    addUserList: {
      userId: '',
      userName: '',
      passWord: '',
      roleName: '',
      roleNames: []
    },
    ifUser: '新增',
    //地区监控列表
    addressCameraListDialog: false,
	addressCameraListDialog1:false,
    addressListpage: 1,
    addressListlength: 1,
    addressLists: [],
    areaIdAddress: '',
    //摄像头数量
    cameraNumber: {},
    dialogDelete: false,
    adddialog: false,
    //摄像头列表、page
    cameraLists: [],
    cameraListpage: 1,
    cameraListlength: 1,
    camerDialogDelete: false,
    cameraId: '',
    camername: '新增',
	userDetails : JSON.parse(sessionStorage.getItem("userDetails")),
    addCamerList: {
      cameraId: '',
      areaId: '',
      cameraLocationValue: '',
      cameraType: '',
      cameraIp: '',
      port: '554',
      cameraUsername: '',
      cameraPassword: '',
      longitude: '',
      latitude: ''
    },
    rules: [(value) => !!value || '请输入'] // 验证
  }),
  computed: {
    transform: function() {
      return 'translateY(-' + this.num * 32 + 'px)'
    }
  },
  created: function() {
    let _this = this
    setInterval(function() {
      if (_this.num !== _this.videoList.length) {
        _this.num++
      } else {
        _this.num = 0
      }
    }, 3000)
    setInterval(function() {
      _this.getOiledCameraList()
      _this.getCameraAbnormal()
      _this.pageChange()
      _this.getUserRoleList()
      _this.getRoleMys()
    }, 60000)
    this.getOiledCameraList()
    this.getCameraAbnormal()
    this.pageChange()
    this.getUserRoleList()
    this.getRoleMys()
  },
  mounted() {
    this.nowTimes();
    this.getChart();
    this.progressBar();
	this.getAreaList();
  },
  watch: {
    percentage1(val) {
      if (val < 100) return
      this.percentage1 = 0
    },
	percentage2(val) {
	  if (val < 100) return
	  this.percentage2 = 0
	},
    ProjectDialog(val) {
      if (!val) {
        this.imageContent.imgPath = ''
        this.imageContent.imgStatus = ''
        this.imageContent.cameraLocationValue = ''
        this.imageContent.imgId = ''
      }
    }
  },
  methods: {
	
	// 获取地域列表
	getAreaList(){
		let _this = this;
		getAreaRole()
		.then((res) => {
			_this.toAreaList(res.data)
		});
	},
	// 地域列表格式化
	toAreaList(data){
		data.forEach((e) => {
		  e.coordinateStyle=JSON.parse(e.coordinateStyle);
		  e.imgStyle=JSON.parse(e.imgStyle);
		  e.lineStyle=JSON.parse(e.lineStyle);
		})
		this.areaList=data;
	},
	//图片拖拽事件
	onDragImg(x, y,i) {
		this.areaList[i].imgStyle.x=x;
		this.areaList[i].imgStyle.y=y;
		this.countLine(i);
	},
	//拖拽事件
	onDrag(x, y,i) {
		this.areaList[i].coordinateStyle.x=x;
		this.areaList[i].coordinateStyle.y=y;
		this.countLine(i);
	},
	//计算line
	countLine(i){
		let aX=this.areaList[i].coordinateStyle.x;
		let aY=this.areaList[i].coordinateStyle.y;
		let iX=this.areaList[i].imgStyle.x;
		let iY=this.areaList[i].imgStyle.y;
		let lX=this.areaList[i].lineStyle.x;
		let a=3,b=4,c=5;
		
		if((aY-iY) > 25 ){//图标在上
			b=(aY-iY)-25;
			this.areaList[i].lineStyle.display="block";
			this.areaList[i].lineStyle.to="bottom";
		}else if(((aY-iY) <= 0 && (aY-iY) > -85) || ((aY-iY) > 0 && (aY-iY) < 25)){//图标中
			b=4;
			this.areaList[i].lineStyle.display="none";
		}else{//图标下
			b=(iY-aY)-75;
			this.areaList[i].lineStyle.y=65;
			this.areaList[i].lineStyle.display="block";
			this.areaList[i].lineStyle.to="top";
		}
		if((iX-aX-lX) > 0){
			a=iX-aX-lX;
		}else if((iX-aX-lX) < -20){
			a=-(iX-aX-lX+10);
		}
		c= parseInt(Math.sqrt(Math.pow(a,2)+Math.pow(b,2)));
		this.areaList[i].lineStyle.h=c;
		if((aY-iY) > 25 ){//图标在上
			this.areaList[i].lineStyle.y=-(c);
			if((iX-aX-lX) > 0){
				this.areaList[i].lineStyle.f=parseInt(Math.asin(a/c)*180/Math.PI);
			}else{
				this.areaList[i].lineStyle.f=-(parseInt(Math.asin(a/c)*180/Math.PI));
			}
			
		}else{
			if((iX-aX-lX) > 0){
				this.areaList[i].lineStyle.f=-(parseInt(Math.asin(a/c)*180/Math.PI));
			}else{
				this.areaList[i].lineStyle.f=parseInt(Math.asin(a/c)*180/Math.PI);
			}
		}
		
	},
	//地区设置
	settingShow(){
		this.isSettingShow=false;
	},
	//地域添加
	addSettingHide(){
		let data={
			"areaId":null,
			"areaName":"地域名称",
			"coordinateStyle":{"x":28,"y":29,"h":65,"w":200},
			"imgStyle":{"x":243,"y":55,"h":20,"w":20},
			"lineStyle":{"x":120,"y":65,"h":95,"f":-90,"display":"none","to":"top"},
			"cameraCount":0,
			"errorCount":0};
		this.areaList.push(data);
	},
	//地域删除
	delectArea(i) {
	  if(this.areaList[i].cameraCount==0 && this.areaList[i].areaId!=null){
		  this.areaDialogDelete = true;
		  this.areaId = this.areaList[i].areaId;
		  this.areaIndex=i;
	  }else if(this.areaList[i].areaId==null || this.areaList[i].areaId==""){
		  this.$delete(this.areaList,i);
	  }else{
		  this.tipsInfo = true
		  this.tipsInfoType = 'error'
		  this.tipsInfoContent = "请先移除此地域"+this.areaList[i].cameraCount+"个摄像头"
		  setTimeout(() => {
		    this.tipsInfo = false
		  }, 2000)
	  }
	},
	//二次确认删除地域
	delectAreaCamer() {
		let _this=this;
	  let data = {
	    areaId: this.areaId
	  }
	  delectArea(data)
	    .then(() => {
		  _this.$delete(_this.areaList,_this.areaIndex);
	      _this.areaDialogDelete = false
	      _this.tipsInfo = true
	      _this.tipsInfoType = 'success'
	      _this.tipsInfoContent = '删除成功'
	      _this.cameraPageChange()
	      setTimeout(() => {
	        _this.tipsInfo = false
	      }, 2000)
	    })
	    .catch((res) => {
	      _this.tipsInfo = true
	      _this.tipsInfoType = 'error'
	      _this.tipsInfoContent = res.data.message
	      setTimeout(() => {
	        _this.tipsInfo = false
	      }, 2000)
	    })
	},
	//地域信息修改保存
	sendSettingHide(){
		let data=this.areaList;
		let _this = this;
		data.forEach(((e) => {
		  e.coordinateStyle=JSON.stringify(e.coordinateStyle);
		  e.imgStyle=JSON.stringify(e.imgStyle);
		  e.lineStyle=JSON.stringify(e.lineStyle);
		}))
		editArea(data)
		  .then(() => {
			_this.getAreaList()
			_this.tipsInfo = true
			_this.tipsInfoType = 'success'
			_this.tipsInfoContent = '修改成功'
			setTimeout(() => {
			  _this.tipsInfo = false
			}, 2000)
		  })
		  .catch((res) => {
			_this.getAreaList()
			_this.tipsInfo = true
			_this.tipsInfoType = 'error'
			_this.tipsInfoContent = res.data.message
			setTimeout(() => {
			  _this.tipsInfo = false
			}, 2000)
		  })
		_this.isSettingShow=true;
	},
    //获取权限
    getRoleMys() {
      let that = this
      getRoleMy()
        .then((res) => {
          res.data.forEach((e) => {
            if (e === 'auth/register') {
              that.showUserList = true
            } else if (e === 'camera/add') {
              that.showCameraList = true
            }
          })
        })
        .catch((res) => {
          if (res === '401') {
            this.$store.dispatch('FedLogOut').then(() => {
              this.$router.push({ path: '/login' })
            })
          }
        })
    },
    //退出登录
    loginOut() {
      this.$store.dispatch('FedLogOut').then(() => {
        this.$router.push({ path: '/login' })
      })
    },

    //地区监控列表
    addressCameraClick(val) {
      this.addressCameraListDialog = true
      this.areaIdAddress = val
      let data = {
        page: this.addressListpage - 1,
        size: 10
      }
      cameraListByAreaId(data, val).then((res) => {
        this.addressLists = res.data.content
        this.addressListlength = res.data.totalPages
      })
    },
    // 地区图片实时预览
    videoPictures(val) {
      this.cameraItemDialog = true
      this.cameraItem = val
    },
    // 地区监控列表分页
    addressPageChange() {
      let data = {
        page: this.addressListpage - 1,
        size: 10,
        areaId: this.areaIdAddress
      }
      cameraList(data).then((res) => {
        this.addressLists = res.data.content
        this.addressListlength = res.data.totalPages
      })
    },
    //获取员工权限列表
    getUserRoleList() {
      getUserRole().then((res) => {
        this.userRoleList = res.data
      })
    },
    //获取员工列表
    showStaffList() {
      this.staffListDialog = true
      this.getUserList()
    },
    //员工列表分页
    userPageChange() {
      let data = {
        page: this.userListpage - 1,
        size: 10
      }
      getUser(data).then((res) => {
        this.userLists = res.data.content
        this.userListlength = res.data.totalPages
      })
    },
    getUserList() {
      this.staffListDialog = true
      let data = {
        page: this.userListpage - 1,
        size: 10
      }
      getUser(data).then((res) => {
        this.userLists = res.data.content
        this.userListlength = res.data.totalPages
      })
    },
    //新增员工
    addUserClick() {
      this.$refs.formUser.resetValidation()
      this.ifUser = '新增'
      this.addUserList = {
        userId: '',
        userName: '',
        passWord: '',
        roleName: ''
      }
    },
	//修改员工
	uoUserPass(data) {
	  this.upUserLog = true
	  this.addUserList = {
	    userId: data.userId,
	    userName: data.userName,
	    roleName: data.roleList[0].roleId
	  }
	},
    //修改员工
    editUserClick(data) {
      this.addUserdialog = true
      this.ifUser = '修改'
      this.addUserList = {
        userId: data.userId,
        userName: data.userName,
        roleName: data.roleList[0].roleId
      }
    },
	//二次确认修改密码
	SureUserChangePass() {
		this.$refs.formUser.validate()
		this.addUserList.roleNames = [this.addUserList.roleName]
		  
		editUser(this.addUserList)
		  .then(() => {
			this.upUserLog = false
			this.tipsInfo = true
			this.tipsInfoType = 'success'
			this.tipsInfoContent = '修改成功'
			setTimeout(() => {
			  this.tipsInfo = false
			}, 2000)
		  })
		  .catch((res) => {
			this.tipsInfo = true
			this.tipsInfoType = 'error'
			this.tipsInfoContent = res.data.message
			setTimeout(() => {
			  this.tipsInfo = false
			}, 2000)
		  })
	  
	},
    //二次确认新增/修改员工
    SureUserClick() {
      this.$refs.formUser.validate()
      this.addUserList.roleNames = [this.addUserList.roleName]
      if (this.ifUser === '新增') {
        addUser(this.addUserList)
          .then(() => {
            this.addUserdialog = false
            this.tipsInfo = true
            this.tipsInfoType = 'success'
            this.tipsInfoContent = '新增员工成功'
            this.getUserList()
            setTimeout(() => {
              this.tipsInfo = false
            }, 2000)
          })
          .catch((res) => {
            this.tipsInfo = true
            this.tipsInfoType = 'error'
            this.tipsInfoContent = res.data.message
            setTimeout(() => {
              this.tipsInfo = false
            }, 2000)
          })
      } else {
        editUser(this.addUserList)
          .then(() => {
            this.addUserdialog = false
            this.tipsInfo = true
            this.tipsInfoType = 'success'
            this.tipsInfoContent = '修改成功'
            this.getUserList()
            setTimeout(() => {
              this.tipsInfo = false
            }, 2000)
          })
          .catch((res) => {
            this.tipsInfo = true
            this.tipsInfoType = 'error'
            this.tipsInfoContent = res.data.message
            setTimeout(() => {
              this.tipsInfo = false
            }, 2000)
          })
      }
    },
    //删除员工
    deleteUserClick(data) {
      this.userDialogDelete = true
      this.userId = data.userId
    },
    //二次确认删除员工
    deleteItemUser() {
      let data = {
        userId: this.userId
      }
      deleteUser(data)
        .then(() => {
          this.userDialogDelete = false
          this.tipsInfo = true
          this.tipsInfoType = 'success'
          this.tipsInfoContent = '删除员工成功'
          this.getUserList()
          setTimeout(() => {
            this.tipsInfo = false
          }, 2000)
        })
        .catch((res) => {
          this.tipsInfo = true
          this.tipsInfoType = 'error'
          this.tipsInfoContent = res.data.message
          setTimeout(() => {
            this.tipsInfo = false
          }, 2000)
        })
    },

    //获取摄像头列表
    getcameraList() {
      this.cameraListDialog = true
      let data = {
        page: this.cameraListpage - 1,
        size: 10
      }
      cameraList(data)
        .then((res) => {
          this.cameraLists = res.data.content
          this.cameraListlength = res.data.totalPages
        })
        .catch((res) => {
          this.tipsInfo = true
          this.tipsInfoType = 'error'
          this.tipsInfoContent = res.data.message
          setTimeout(() => {
            this.tipsInfo = false
          }, 2000)
        })
    },
    //摄像头列表分页
    cameraPageChange() {
      let data = {
        page: this.cameraListpage - 1,
        size: 10
      }
      cameraList(data).then((res) => {
        this.cameraLists = res.data.content
        this.cameraListlength = res.data.totalPages
      })
    },
    //新增、修改摄像头
    wenzi() {
      this.camername = '新增'
      this.$refs.form.resetValidation()
      this.addCamerList = {
        cameraId: '',
        areaId: '',
        cameraLocationValue: '',
        cameraType: '',
        cameraIp: '',
        port: '554',
        cameraUsername: '',
        cameraPassword: '',
        latitude: '',
        longitude: ''
      }
    },
    editcamerClick(data) {
      this.adddialog = true
      this.camername = '修改'
      this.addCamerList = {
        cameraId: data.cameraId,
        areaId: data.area.areaId,
        cameraLocationValue: data.cameraLocationValue,
        cameraType: String(data.cameraType),
        cameraIp: data.cameraIp,
        port: data.port,
        cameraUsername: data.cameraUsername,
        cameraPassword: data.cameraPassword,
        latitude: data.latitude,
        longitude: data.longitude
      }
    },
    //摄像头新增修改二次确认
    cameraListClick() {
      if (this.$refs.form.validate()) {
        if (this.camername === '新增') {
          addCamera(this.addCamerList)
            .then(() => {
              this.adddialog = false
              this.tipsInfo = true
              this.tipsInfoType = 'success'
              this.tipsInfoContent = '新增摄像头成功'
              this.cameraPageChange()
              setTimeout(() => {
                this.tipsInfo = false
              }, 2000)
            })
            .catch((res) => {
              this.tipsInfo = true
              this.tipsInfoType = 'error'
              this.tipsInfoContent = res.data.message
              setTimeout(() => {
                this.tipsInfo = false
              }, 2000)
            })
        } else {
          editCamera(this.addCamerList)
            .then(() => {
              this.adddialog = false
              this.tipsInfo = true
              this.tipsInfoType = 'success'
              this.tipsInfoContent = '修改摄像头成功'
              this.cameraPageChange()
              setTimeout(() => {
                this.tipsInfo = false
              }, 2000)
            })
            .catch((res) => {
              this.tipsInfo = true
              this.tipsInfoType = 'error'
              this.tipsInfoContent = res.data.message
              setTimeout(() => {
                this.tipsInfo = false
              }, 2000)
            })
        }
      }
    },
    //删除摄像头
    deletecamerClick(data) {
      this.camerDialogDelete = true
      this.cameraId = data.cameraId
    },
    //二次确认删除摄像头
    deleteItemCamer() {
      let data = {
        cameraId: this.cameraId
      }
      deleteCamera(data)
        .then(() => {
          this.camerDialogDelete = false
          this.tipsInfo = true
          this.tipsInfoType = 'success'
          this.tipsInfoContent = '删除成功'
          this.cameraPageChange()
          setTimeout(() => {
            this.tipsInfo = false
          }, 2000)
        })
        .catch((res) => {
          this.tipsInfo = true
          this.tipsInfoType = 'error'
          this.tipsInfoContent = res.data.message
          setTimeout(() => {
            this.tipsInfo = false
          }, 2000)
        })
    },
    //地图进度条
    progressBar() {
      setInterval(() => {
        this.percentage1 += 1
		this.percentage2 += 1
      }, 300)
    },

    updateImageClick(val, e) {
      let that = this
      let data = {
        imgId: e,
        imgStatus: val
      }
      updateImage(data).then(() => {
        that.pageChange()
        this.ProjectDialog = false
      })
    },
    //获取当前时间
    timeFormate(timeStamp) {
      let year = new Date(timeStamp).getFullYear()
      let month =
        new Date(timeStamp).getMonth() + 1 < 10
          ? '0' + (new Date(timeStamp).getMonth() + 1)
          : new Date(timeStamp).getMonth() + 1
      let date =
        new Date(timeStamp).getDate() < 10 ? '0' + new Date(timeStamp).getDate() : new Date(timeStamp).getDate()
      let hh =
        new Date(timeStamp).getHours() < 10 ? '0' + new Date(timeStamp).getHours() : new Date(timeStamp).getHours()
      let mm =
        new Date(timeStamp).getMinutes() < 10
          ? '0' + new Date(timeStamp).getMinutes()
          : new Date(timeStamp).getMinutes()
      let ss =
        new Date(timeStamp).getSeconds() < 10
          ? '0' + new Date(timeStamp).getSeconds()
          : new Date(timeStamp).getSeconds()
      var d = new Date(timeStamp).getDay()
      let day
      if (d === 0) {
        day = '日'
      } else if (d === 1) {
        day = '一'
      } else if (d === 2) {
        day = '二'
      } else if (d === 3) {
        day = '三'
      } else if (d === 4) {
        day = '四'
      } else if (d === 5) {
        day = '五'
      } else if (d === 6) {
        day = '六'
      }
      this.nowTime = hh + ':' + mm + ':' + ss + '   ' + year + '/' + month + '/' + date + '   ' + '星期' + day
    },
    nowTimes() {
      this.timeFormate(new Date())
      setInterval(this.nowTimes, 1000)
      this.clear()
    },
    clear() {
      clearInterval(this.nowTimes)
      this.nowTimes = null
    },
    videoClick(val) {
      this.ProjectDialog = true
      this.imageContent.imgId = val.imgId
      this.imageContent.imgStatus = val.imgStatus
      this.imageContent.imgPath = val.imgPath
      this.imageContent.cameraLocationValue = val.camera.cameraLocationValue
    },
    //获取chart图
    getChart() {
      let dataTitle = []
      let dataNumber = []
      getAreaCameraCount()
        .then((res) => {
          this.chartList = res.data
          res.data.forEach((e) => {
            dataTitle.push(e.areaName)
            dataNumber.push(e.cameraCount)
          })
          let myChart = this.$echarts.init(document.getElementById('monitorTotal'))
          // 绘制图表
          myChart.setOption({
            xAxis: {
              type: 'category',
              data: dataTitle,
              axisLine: {
                show: true,
                lineStyle: {
                  color: '#666'
                }
              },
              axisLabel: {
                show: true,
                textStyle: {
                  fontSize: 8,
                  fontFamily: 'PingFang-SC-Medium',
                  color: '#ccc'
                },
                // 设置字体的倾斜角度
                interval: 0,
                rotate: 0
              }
            },
            yAxis: {
              type: 'value',
              axisLine: {
                show: true,
                lineStyle: {
                  color: '#666'
                }
              },
              axisLabel: {
                show: true,
                textStyle: {
                  fontSize: 12,
                  fontFamily: 'PingFang-SC-Medium',
                  color: '#ccc'
                }
              },
              splitLine: {
                //格线样式
                lineStyle: {
                  color: '#666'
                }
              }
            },
            series: [
              {
                barWidth: 25,
                data: dataNumber,
                type: 'bar',
                itemStyle: {
                  normal: {
                    color: '#2F89CF'
                  }
                }
              }
            ]
          })
        })
        .catch((res) => {
          this.tipsInfo = true
          this.tipsInfoType = 'error'
          this.tipsInfoContent = res.data.message
          setTimeout(() => {
            this.tipsInfo = false
          }, 2000)
        })
    },
    //获取异常状态/地图/摄像头数量
    getCameraAbnormal() {
      getQueryCameraCountGroup().then((res) => {
        this.cameraCountGroupList = res.data
      })
      cameraCountNumber().then((res) => {
        this.cameraNumber = res.data
      })
    },

    pageChange() {
      let date = {
        page: this.page - 1,
        size: 5
      }
      cameraIssueList(date).then((res) => {
        this.leakOilIssues = res.data.content
        this.length = res.data.totalPages
      })
    },
    //获取受油地址列表
    getOiledCameraList() {
      cameraList({ page: 0, size: 20, sort: 'lastCheckTime,desc' }).then((res) => {
        this.videoList = res.data.content
      })
    },
    deleteItem() {
      this.dialogDelete = true
    },
    close() {
    },
    closeDelete() {
      this.dialogDelete = false
    },
    deleteItemConfirm() {
      this.dialogDelete = false
    }
  }
}
</script>

<style lang='scss'>
	@import "../../styles/home.scss";
	.helloword {
	  overflow: hidden;
	}
	.text-event {
	  float: left;
	  height: 500px;
	  width: 500px;
	  border: 1px solid red;
	  position: relative;
	　background: linear-gradient(-90deg, rgba(0, 0, 0, 0.1) 1px, transparent 1px) 0% 0% / 10px 10px, linear-gradient(rgba(0, 0, 0, 0.1) 1px, transparent 1px) 0% 0% / 10px 10px;
	
	}
	.p-event {
	  float: left;
	  height: 300px;
	  width: 300px;
	  border: 1px solid blue;
	  position: relative;
	  margin-left: 20px;
	}
	
	.dragging1 {
	  border: 1px solid #000;
	  color: #000;
	}
</style>
